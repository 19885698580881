<template>
  <div class="navigation">
    <div class="container">
      <div class="inside">
        <div class="nav nav-tab menu">
          <router-link
            class="btn"
            to="/settings"
            data-toggle="tab"
          >
            <img
              class="avatar-xl"
              src="https://pp.userapi.com/c848416/v848416437/ef92a/9jzdLOMIsU0.jpg?ava=1"
              alt="avatar"
            />
          </router-link>
          <router-link to="/members" data-toggle="tab">
            <i class="material-icons">account_circle</i>
          </router-link>
          <router-link to="/discussions" data-toggle="tab">
            <i class="material-icons">chat_bubble_outline</i>
          </router-link>
          <router-link to="/notifications" data-toggle="tab" class="f-grow1">
            <i class="material-icons">extension</i>
          </router-link>
          <button @click="toggleTheme" class="btn mode" :class="{ active: theme === 'dark' }">
            <i class="material-icons">brightness_2</i>
          </button>
          <router-link to="/settings" data-toggle="tab">
            <i class="material-icons">settings</i>
          </router-link>
          <button class="btn power" onclick="visitPage()">
            <i class="material-icons">power_settings_new</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue-awesome-material-icons/icons/power_settings_new'

export default {
  name: 'Navigation',
  methods: {
    toggleTheme() {
      const body = document.querySelector('body')
      body.classList.toggle('dark')

      if (body.classList.contains('dark')) {
        this.$store.commit('changeTheme', 'dark')
      } else {
        this.$store.commit('changeTheme', 'light')
      }
    }
  },
  computed: {
    theme() {
      return this.$store.getters['getTheme']
    }
  }
}
</script>