import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Members from '@/views/Members'
import Discussions from '@/views/Discussions'
import Notifications from '@/views/Notifications'
import Settings from '@/views/Settings'

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/members', name: 'Members', component: Members },
  { path: '/discussions', name: 'Discussions', component: Discussions },
  { path: '/notifications', name: 'Notifications', component: Notifications },
  { path: '/settings', name: 'Settings', component: Settings },
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  linkExactActiveClass: 'active',
})

export default router