import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'

function patchRouterMethod (router, methodName) {
  router['old' + methodName] = router[methodName]
  router[methodName] = async function (location) {
    return router['old' + methodName](location).catch((error) => {
      if (error.name === 'NavigationDuplicated') {
        return this.currentRoute
      }
      throw error
    })
  }
}

Vue.config.productionTip = false

patchRouterMethod(router, 'push')
patchRouterMethod(router, 'replace')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')