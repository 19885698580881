<template>
  <div id="home" class="tab-pane">
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>