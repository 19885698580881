<template>
  <div class="babble tab-pane" id="profile">
    <div class="chat profile">
      <div class="top">
        <div class="container">
          <div class="col-md-12">
            <div class="inside">
              <a href="#"
                ><img
                  id="ProfileAva"
                  class="avatar-md"
                  src="https://pp.userapi.com/c840620/v840620516/412b7/uJf8LgRVDfY.jpg?ava=1"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  alt=""
              /></a>
              <div class="data">
                <h5><a id="ProfileName" href=""></a></h5>
                <span id="ProfileStatus"></span>
              </div>
              <button class="btn connect d-md-block d-none" name="1">
                <i class="material-icons md-30">phone_in_talk</i>
              </button>
              <button class="btn connect d-md-block d-none" name="1">
                <i class="material-icons md-36">videocam</i>
              </button>
              <button class="btn d-md-block d-none">
                <i class="material-icons md-30">info</i>
              </button>
              <div class="dropdown">
                <button class="btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="material-icons md-30">more_vert</i>
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button class="dropdown-item connect" name="1">
                    <i class="material-icons">phone_in_talk</i>Voice Call
                  </button>
                  <button class="dropdown-item connect" name="1">
                    <i class="material-icons">videocam</i>Video Call
                  </button>
                  <hr />
                  <button class="dropdown-item">
                    <i class="material-icons">clear</i>Clear History
                  </button>
                  <button class="dropdown-item">
                    <i class="material-icons">block</i>Block Contact
                  </button>
                  <button class="dropdown-item">
                    <i class="material-icons">delete</i>Delete Contact
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content" id="content">
        <div class="container">
          <div class="col-md-12">Тут профиль</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
}
</script>