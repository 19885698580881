<template>
  <div class="sidebar" id="sidebar">
    <div class="container">
      <div class="col-md-12">
        <div class="tab-content">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
}
</script>