<template>
  <div class="tab-pane" id="members">
    <div class="search">
      <form class="form-inline position-relative">
        <input type="search" class="form-control" id="people" placeholder="Search for people...">
        <button type="button" class="btn btn-link loop"><i class="material-icons">search</i></button>
      </form>
      <button class="btn create" data-toggle="modal" data-target="#exampleModalCenter"><i class="material-icons">person_add</i></button>
    </div>
    <div class="list-group sort">
      <button class="btn filterMembersBtn active show" data-toggle="list" data-filter="all">All</button>
      <button class="btn filterMembersBtn" data-toggle="list" data-filter="online">Online</button>
      <button class="btn filterMembersBtn" data-toggle="list" data-filter="offline">Offline</button>
    </div>
    <div class="contacts">
      <h1>Друзья</h1>
      <div class="list-group" id="contacts">
        <a v-for="member in members" :key="member.id" @click="loadProfile(member.id)" :class="getMemberClass(member)">
          <img class="avatar-md" :src="member.avatar" data-toggle="tooltip" data-placement="top" :title="member.name" alt="avatar">
          <div class="status" :class="member.online ? 'online' : 'offline'"></div>
          <div class="data">
            <h5>{{ member.name }}</h5>
            <p>{{ member.location }}</p>
          </div>
          <div class="person-add">
          <i class="material-icons">person</i>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Members',
  data() {
    return {
      selected: 0,
      members: [
        {
          id: 2,
          online: true,
          name: 'Maxim Morozov',
          avatar: 'https://pp.userapi.com/c848416/v848416437/ef92a/9jzdLOMIsU0.jpg?ava=1',
          location: 'Bali, Indonesia'
        },
        {
          id: 1,
          online: true,
          name: 'Artem Yablonev',
          avatar: 'https://pp.userapi.com/c840620/v840620516/412b7/uJf8LgRVDfY.jpg?ava=1',
          location: 'Bali, Indonesia'
        },
        {
          id: 3,
          online: false,
          name: 'Dima Putsyk',
          avatar: 'https://pp.userapi.com/c845018/v845018265/1725fb/RM0FVskUiEo.jpg?ava=1',
          location: 'Vienna, Austria'
        }
      ]
    }
  },
  methods: {
    loadProfile(id) {
      this.selected = id
    },
    getMemberClass(member) {
      const c = ['filterMembers', 'all', 'contact']
      if (member.id === this.selected) c.push('active')
      c.push(member.online ? 'online' : 'offline')
      return c.join(' ')
    }
  }
}
</script>