<template>
  <section>
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="requests">
          <div class="title">
            <h1>Add your friends</h1>
            <button
              type="button"
              class="btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="material-icons">close</i>
            </button>
          </div>
          <div class="content">
            <form>
              <div class="form-group">
                <label for="user">Username:</label>
                <input
                  type="text"
                  class="form-control"
                  id="user"
                  placeholder="Add recipient..."
                  required
                />
                <div class="user" id="contact">
                  <img
                    class="avatar-sm"
                    src="https://pp.userapi.com/c840620/v840620516/412b7/uJf8LgRVDfY.jpg?ava=1"
                    alt="avatar"
                  />
                  <h5>Artem Yablonev</h5>
                  <button class="btn"><i class="material-icons">close</i></button>
                </div>
              </div>
              <div class="form-group">
                <label for="welcome">Message:</label>
                <textarea
                  class="text-control"
                  id="welcome"
                  placeholder="Send your welcome message..."
                >
  Hi Keith, I'd like to add you as a contact.</textarea
                >
              </div>
              <button type="submit" class="btn button w-100">
                Send Friend Request
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="startnewchat"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="requests">
          <div class="title">
            <h1>Start new chat</h1>
            <button
              type="button"
              class="btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="material-icons">close</i>
            </button>
          </div>
          <div class="content">
            <form>
              <div class="form-group">
                <label for="participant">Recipient:</label>
                <input
                  type="text"
                  class="form-control"
                  id="participant"
                  placeholder="Add recipient..."
                  required
                />
                <div class="user" id="recipient">
                  <img
                    class="avatar-sm"
                    src="https://pp.userapi.com/c840620/v840620516/412b7/uJf8LgRVDfY.jpg?ava=1"
                    alt="avatar"
                  />
                  <h5>Artem Yablonev</h5>
                  <button class="btn"><i class="material-icons">close</i></button>
                </div>
              </div>
              <div class="form-group">
                <label for="topic">Topic:</label>
                <input
                  type="text"
                  class="form-control"
                  id="topic"
                  placeholder="What's the topic?"
                  required
                />
              </div>
              <div class="form-group">
                <label for="message">Message:</label>
                <textarea class="text-control" placeholder="Send your welcome message...">Hmm, are you friendly?</textarea>
              </div>
              <button type="submit" class="btn button w-100">
                Start New Chat
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Modals',
}
</script>