<template>
  <main>
    <div class="layout">
      <Navigation />
      <Sidebar />
      <Modals />
      <div class="main">
        <div class="tab-content" id="nav-tabContent">
          <Dialog v-if="isShown('dialog')" />
          <Profile v-if="isShown('profile')" />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Navigation from '@/components/Navigation'
import Sidebar from '@/components/Sidebar'
import Modals from '@/components/Modals'
import Dialog from '@/components/Dialog'
import Profile from '@/components/Profile'

import '@/assets/sass/bootstrap.sass'
import '@/assets/sass/swipe.sass'
import '@/assets/sass/dark.sass'
import '@/assets/sass/theme.sass'

export default {
  name: 'App',
  components: { Navigation, Sidebar, Modals, Dialog, Profile },
  mounted() {
    const theme = localStorage.getItem('theme')
    if (theme) this.$store.commit('changeTheme', theme)
  },
  methods: {
    isShown(type) {
      if (type === 'dialog') return true
      return false
    }
  }
}
          // function scrollToBottom(el) { el.scrollTop = el.scrollHeight; }
          // scrollToBottom(document.getElementById('content'));

          // function visitPage()
          // {
          // window.location = "account"
          // }

          // $(".contacts a.contact").on("click", function()
          // {
          // $("#profile").show();
          // $(".list-group a.contact").removeClass("active");
          // $(this).addClass("active");

          // var name = $(this).find(".data h5").text();
          // if($(this).hasClass("single"))
          // {
          // var status = $(this).find(".status").attr('class');
          // status = status.replace("status ", "");
          // }
          // var ava = $(this).find("img").attr("src");

          // $("#ProfileName").text(name);
          // $("#ProfileStatus").text(status);
          // $("#ProfileAva").attr("src", ava);
          // $("#ProfileAva").attr("alt", name);
          // $("#ProfileAva").attr("title", name);
          // $("#ProfileAva").attr("data-original-title", name);
          // });

          // $(".list-group a.single, .list-group a.notification").on("click", function()
          // {
          // $("#chat").show();
          // $(".list-group a.single").removeClass("active");
          // $(".list-group a.notification").removeClass("active");
          // $(this).addClass("active");
          // $(this).find(".new").remove();
          // $(this).removeClass("unread");
          // var name = $(this).find(".data h5").text();
          // if($(this).hasClass("single"))
          // {
          // var status = $(this).find(".status").attr('class');
          // status = status.replace("status ", "");
          // }
          // var ava = $(this).find("img").attr("src");

          // $("#ActiveChatName").text(name);
          // $("#ActiveChatStatus").text(status);
          // $("#ActiveChatAva").attr("src", ava);
          // $("#ActiveChatAva").attr("alt", name);
          // $("#ActiveChatAva").attr("title", name);
          // $("#ActiveChatAva").attr("data-original-title", name);

          // $("#call .avatar-xxl").attr("src", ava);
          // $(".chat").show();
          // });

          // $(".menu a i").on("click", function()
          // {
          // $(".menu a i").removeClass("active"), $(this).addClass("active");
          // });

          // $("#contact, #recipient").click(function()
          // {
          // $(this).remove();
          // });

          // $(function()
          // {
          // $('[data-toggle="tooltip"]').tooltip();
          // });

          // $(document).ready(function()
          // {
          // $(".filterMembers").not(".all").hide("3000"), $(".filterMembers").not(".all").hide("3000"), $(".filterMembersBtn").click(function()
          // {
          // var t = $(this).attr("data-filter");
          // $(".filterMembers").not("." + t).hide("3000"), $(".filterMembers").filter("." + t).show("3000")
          // });
          // });

          // $(document).ready(function()
          // {
          // $(".filterDiscussions").not(".all").hide("3000"), $(".filterDiscussions").not(".all").hide("3000"), $(".filterDiscussionsBtn").click(function()
          // {
          // var t = $(this).attr("data-filter");
          // $(".filterDiscussions").not("." + t).hide("3000"), $(".filterDiscussions").filter("." + t).show("3000")
          // });
          // });

          // $(document).ready(function()
          // {
          // $(".filterNotifications").not(".all").hide("3000"), $(".filterNotifications").not(".all").hide("3000"), $(".filterNotificationsBtn").click(function()
          // {
          // var t = $(this).attr("data-filter");
          // $(".filterNotifications").not("." + t).hide("3000"), $(".filterNotifications").filter("." + t).show("3000")
          // });
          // });

// $(document).ready(function()
// {
// $("#people").on("keyup", function()
// {
// var t = $(this).val().toLowerCase();
// $("#contacts a").filter(function()
// {
// $(this).toggle($(this).text().toLowerCase().indexOf(t) > -1)
// })
// });
// });

// $(document).ready(function()
// {
// $("#conversations").on("keyup", function()
// {
// var t = $(this).val().toLowerCase();
// $("#chats a").filter(function() {
// $(this).toggle($(this).text().toLowerCase().indexOf(t) > -1)
// })
// });
// });

// $(document).ready(function()
// {
// $("#notice").on("keyup", function()
// {
// var t = $(this).val().toLowerCase();
// $("#alerts a").filter(function()
// {
// $(this).toggle($(this).text().toLowerCase().indexOf(t) > -1)
// })
// });
// });

        // $("a[show]").click(function()
        // {
        // var show = $(this).attr("show");
        // MainUI();
        // if(show=="Friends"){$(".list-group a.contact").removeClass("active");}
        // if(show=="Dialogs"){$(".list-group a.single").removeClass("active");}
        // if(show=="Bots"){$(".list-group a.notification").removeClass("active");}
        // });

        // function MainUI()
        // {
        // $("#chat").hide();
        // $("#profile").hide();
        // }

        // $(document).ready(function()
        // {
        // clicked = !0;

        // $(".mode").click(function()
        // {
        // clicked ? ($("body").addClass("dark"), $("head").append('<link href="assets/css/dark.min.css?<?php echo time(); ?>" id="dark" rel="stylesheet">'), clicked = !1) : ($("body").removeClass("dark"), $("#dark").remove(), clicked = !0);
        // });
        // });

        // $(".back").click(function()
        // {
        // $("#call" + $(this).attr("name")).hide(), $("#chat" + $(this).attr("name")).removeAttr("style");
        // });

        // $(".connect").click(function()
        // {
        // $(".chat").hide(), $("#call").show();
        // });

        // $(".call-end").click(function()
        // {
        // $(".chat").show(), $("#call").hide();
        // });

        // $("#message").click(function()
        // {
        // $("#message span").remove();
        // });

        // function Send()
        // {
        // var message = $("#message").text();
        // message = message.trim();
        // if(message!='')
        // {
        // $("#message").text('')
        // var timeSent = '<?php echo date('h:i A'); ?>';
        // $("#AllChatMessages").append(`
        // <div class="message me">
        // <div class="text-main">
        // <div class="text-group me">
        // <div class="text me">
        // <p>`+message+`</p>
        // </div>
        // </div>
        // <span><i class="material-icons">check" />`+timeSent+`</span>
        // </div>
        // </div>`);

        // var typing = false;

        // var typeEl = $(".message .text.typing").closest(".message");
        // typeEl.remove();

        // if(typing)
        // {
        // $("#AllChatMessages").append(`<div class="message">`+typeEl.html()+`</div>`);
        // }

        // var scroll=$('.content#content');
        // scroll.animate({scrollTop: scroll.prop("scrollHeight")});
        // }
        // }

        // $("select#theme").change(function()
        // {
        // ApplyTheme($(this).val());
        // });

        // $("#message").keypress(function(event)
        // {
        // if (event.keyCode == 13 && !event.shiftKey)
        // {
        // event.preventDefault();
        // Send();
        // return false;
        // }
        // });

        // function ApplyTheme(theme)
        // {
        // $('link#theme').attr("href", "assets/css/theme/"+theme+".css?<?php echo time(); ?>");
        // }
</script>