import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    theme: 'dark',
    user: {
      name: 'Maxim Morozov',
      firstName: 'Maxim',
      lastName: 'Morozov',
      avatar: 'https://pp.userapi.com/c848416/v848416437/ef92a/9jzdLOMIsU0.jpg?ava=1',
      location: 'Austria, Bern',
      alias: 'morake',
      email: 'vk_club@bk.ru',
      theme: 'vk',
      fellas: 122,
      chats: 305,
      posts: 1538,
      lang: 'en'
    }
  },
  mutations: {
    changeTheme(state, payload) {
      localStorage.setItem('theme', payload)
      state.theme = payload
      const body = document.querySelector('body')
      if (payload === 'dark' && !body.classList.contains('dark')) body.classList.add('dark')
    }
  },
  getters: {
    getUser: state => state.user,
    getTheme: state => state.theme
  }
})