<template>
  <div id="discussions" class="tab-pane">
    <div class="search">
      <form class="form-inline position-relative">
        <input type="search" v-model="search" class="form-control" id="conversations" placeholder="Search..." />
        <button type="button" class="btn btn-link loop">
          <i class="material-icons">search</i>
        </button>
      </form>
      <button class="btn create" data-toggle="modal" data-target="#startnewchat">
        <i class="material-icons">create</i>
      </button>
    </div>
    <div class="list-group sort">
      <button class="btn filterDiscussionsBtn active show" data-toggle="list" data-filter="all">
        All
      </button>
      <button class="btn filterDiscussionsBtn" data-toggle="list" data-filter="read">
        Read
      </button>
      <button class="btn filterDiscussionsBtn" data-toggle="list" data-filter="unread">
        Unread
      </button>
    </div>
    <div class="discussions">
      <h1>Диалоги</h1>
      <div class="list-group" id="chats">
        <a v-for="chat in chats" :key="chat.id" @click="loadChat(chat.id)" :class="getChatClass(chat)">
          <img
            class="avatar-md"
            :src="chat.avatar"
            data-toggle="tooltip"
            data-placement="top"
            :title="chat.name"
            alt="avatar"
          />
          <div class="status" :class="chat.online ? 'online' : 'offline'"></div>
          <div v-if="chat.unread" class="new theme">
            <span>{{ chat.unread }}</span>
          </div>
          <div class="data">
            <h5>{{ chat.name }}</h5>
            <span>{{ chat.date }}</span>
            <p>{{ chat.text }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Discussions',
  data() {
    return {
      selected: 0,
      search: '',
      chats: [
        {
          id: 2,
          name: 'Maxim Morozov',
          avatar: 'https://pp.userapi.com/c848416/v848416437/ef92a/9jzdLOMIsU0.jpg?ava=1',
          online: true,
          date: 'Sun',
          unread: 1,
          text: `Faceless Void`
        },
        {
          id: 1,
          name: 'Артём Яблонев',
          avatar: 'https://pp.userapi.com/c840620/v840620516/412b7/uJf8LgRVDfY.jpg?ava=1',
          online: true,
          date: 'Fri',
          unread: 0,
          text: `Hi, it's me, Yablonev, writing you from 51-zone...`
        },
        {
          id: 3,
          name: 'Artem Yablonev',
          avatar: 'https://pp.userapi.com/c639428/v639428959/5b726/4-ze7eBNlcg.jpg?ava=1',
          online: false,
          date: '5 mins',
          unread: 0,
          text: `Гоу арму мб, я даже не знаю, там не сложно`
        },
        {
          id: 4,
          name: 'Катя Советская',
          avatar: 'https://pp.userapi.com/c831309/v831309604/1e7523/9KH19TZtmIU.jpg',
          online: false,
          date: 'Mon',
          unread: 0,
          text: `Maxim, plz don't ignore me!!!`
        }
      ]
    }
  },
  methods: {
    getChatClass(chat) {
      const c = ['filterDiscussions', 'all', 'single']
      c.push(chat.unread > 0 ? 'unread' : 'read')
      if (chat.id === this.selected) c.push('active')
      return c.join(' ')
    },
    loadChat(id) {
      this.selected = id
    }
  }
}
</script>