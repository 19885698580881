<template>
  <div
    class="babble tab-pane"
    id="chat"
    role="tabpanel"
    aria-labelledby="list-chat-list"
  >
    <div class="chat">
      <div class="top">
        <div class="container">
          <div class="col-md-12">
            <div class="inside">
              <a href="#"
                ><img
                  id="ActiveChatAva"
                  class="avatar-md"
                  src="https://pp.userapi.com/c840620/v840620516/412b7/uJf8LgRVDfY.jpg?ava=1"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  alt=""
              /></a>
              <div class="data">
                <h5><a id="ActiveChatName" href=""></a></h5>
                <span id="ActiveChatStatus"></span>
              </div>
              <button class="btn connect d-md-block d-none" name="1">
                <i class="material-icons md-30">phone_in_talk</i>
              </button>
              <button class="btn connect d-md-block d-none" name="1">
                <i class="material-icons md-36">videocam</i>
              </button>
              <button class="btn d-md-block d-none">
                <i class="material-icons md-30">info</i>
              </button>
              <div class="dropdown">
                <button
                  class="btn"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i class="material-icons md-30" name="more_vert" />
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                  <button class="dropdown-item connect" name="1">
                    <i class="material-icons">phone_in_talk</i>Voice Call
                  </button>
                  <button class="dropdown-item connect" name="1">
                    <i class="material-icons">videocam</i>Video Call
                  </button>
                  <hr />
                  <button class="dropdown-item">
                    <i class="material-icons">clear</i>Clear History
                  </button>
                  <button class="dropdown-item">
                    <i class="material-icons">block</i>Block Contact
                  </button>
                  <button class="dropdown-item">
                    <i class="material-icons">delete</i>Delete Contact
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content empty" id="content">
        <div class="container">
          <div class="col-md-12" id="AllChatMessages">
            <div v-if="friendRequest" class="no-messages request">
              <a href=""><img class="avatar-xl" src="https://pp.userapi.com/c845018/v845018265/1725fb/RM0FVskUiEo.jpg?ava=1" data-toggle="tooltip" data-placement="top" title="Dima" alt="avatar"></a>
              <h5>Dima Putsyk would like to add you as a contact.<br><span>Hi Maxim, I'd like to add you as a contact.</span></h5>
              <div class="options">
                <button class="btn button"><i class="material-icons">check</i></button>
                <button class="btn button"><i class="material-icons">close</i></button>
              </div>
            </div>
            <div v-if="messages.length === 0" class="no-messages">
              <i class="material-icons md-48">forum</i>
              <p>Seems people are shy to start the chat.<br>Break the ice send the first message.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="col-md-12">
          <div class="bottom">
            <div class="position-relative w-100">
              <div :contenteditable="true" @input="onInput" id="message" class="form-control">
                <span>{{ message }}</span>
              </div>
              <button class="btn emoticons">
                <i class="material-icons">insert_emoticon</i>
              </button>
              <button onclick="Send()" class="btn send">
                <i class="material-icons">send</i>
              </button>
            </div>
            <label>
              <input type="file" />
              <span class="btn attach d-sm-block d-none"
                ><i class="material-icons">attach_file</i></span
              >
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="call" id="call">
      <div class="content">
        <div class="container">
          <div class="col-md-12">
            <div class="inside">
              <div class="participant">
                <img
                  class="avatar-xxl"
                  src="https://pp.userapi.com/c840620/v840620516/412b7/uJf8LgRVDfY.jpg?ava=1"
                  alt="avatar"
                />
                <span>Connecting</span>
              </div>
              <div class="options">
                <button class="btn option">
                  <i class="material-icons md-30">mic</i>
                </button>
                <button class="btn option">
                  <i class="material-icons md-30">videocam</i>
                </button>
                <button class="btn option call-end">
                  <i class="material-icons md-30">call_end</i>
                </button>
                <button class="btn option">
                  <i class="material-icons md-30">person_add</i>
                </button>
                <button class="btn option">
                  <i class="material-icons md-30">volume_up</i>
                </button>
              </div>
              <button class="btn back" name="1">
                <i class="material-icons md-24">chat</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  data() {
    return {
      message: 'Напишите сообщение...',
      friendRequest: false,
      messages: []
    }
  },
  methods: {
    onInput(e) {
      this.message = e.target.innerText
    },
  },
}
</script>