<template>
  <div id="notifications" class="tab-pane">
    <div class="search">
      <form class="form-inline position-relative">
        <input
          type="search"
          class="form-control"
          id="notice"
          placeholder="Filter notifications..."
        />
        <button type="button" class="btn btn-link loop">
          <i class="material-icons filter-list">filter_list</i>
        </button>
      </form>
    </div>
    <div class="list-group sort">
      <button
        class="btn filterNotificationsBtn active show"
        data-toggle="list"
        data-filter="all"
      >
        All
      </button>
      <button
        class="btn filterNotificationsBtn"
        data-toggle="list"
        data-filter="latest"
      >
        Latest
      </button>
      <button
        class="btn filterNotificationsBtn"
        data-toggle="list"
        data-filter="oldest"
      >
        Oldest
      </button>
    </div>
    <div class="notifications">
      <h1>Боты</h1>
      <div class="list-group" id="alerts">
        <a v-for="bot in bots" :key="bot.id" @click="loadBot(bot.id)" :class="getBotClass(bot)">
          <img class="avatar-md" :src="bot.avatar"
            data-toggle="tooltip"
            data-placement="top"
            :title="bot.name"
            alt="avatar"
          />
          <div v-if="bot.unread" class="new theme">
            <span>{{ bot.unread }}</span>
          </div>
          <div class="data">
            <h5>{{ bot.name }}</h5>
            <span>{{ bot.date }}</span>
            <p>{{ bot.text }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notifications',
  data() {
    return {
      selected: 0,
      bots: [
        {
          id: -1,
          name: 'Yablonev AI',
          avatar: 'https://pp.userapi.com/c850728/v850728323/19c19/5Vx-oUG8kr8.jpg?ava=1',
          unread: 1,
          date: 'Mon',
          text: 'Your balance was updated'
        },
        {
          id: -2,
          name: 'Deep Bot',
          avatar: 'https://pp.userapi.com/c824604/v824604530/d37fc/joh1CrYsAUQ.jpg?ava=1',
          unread: 0,
          date: 'Fri',
          text: 'Your account was updated. Check it out...'
        }
      ]
    }
  },
  methods: {
    getBotClass(bot) {
      const c = ['filterNotifications', 'all', 'notification']
      c.push(bot.unread > 0 ? 'unread' : 'read')
      if (bot.id === this.selected) c.push('active')
      return c.join(' ')
    },
    loadBot(id) {
      this.selected = id
    },
  }
}
</script>